import {
  Alert,
  Anchor,
  Button,
  Card,
  Center,
  Checkbox,
  Divider,
  Flex,
  Group,
  PasswordInput,
  Stack,
  TextInput
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { ActionFunctionArgs, LoaderFunctionArgs, json, redirect } from "@remix-run/node"
import { Form, useActionData, useLoaderData } from "@remix-run/react"
import { IconCircleCheck, IconExclamationCircle } from "@tabler/icons-react"

import { apiFetchClient } from "@/clients"
import Omu from "@/components/Omu"
import { commitSession, destroySession, getSession } from "@/session"

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await getSession(request.headers.get("Cookie"))
  const sessionToken = session.get("sessionToken")

  if (sessionToken) {
    const { error } = await apiFetchClient.GET("/users/me", {
      headers: {
        "X-Session-Token": sessionToken
      }
    })
    if (error) {
      return redirect("/login", { headers: { "Set-Cookie": await destroySession(session) } })
    }

    return redirect("/dashboard")
  }

  return null
}

export default function Index() {
  const actionData = useActionData<typeof action>()

  const form = useForm({
    initialValues: { email: "", password: "" },
    validate: {
      email: (value) => (!value ? "Email is required" : null),
      password: (value) => (!value ? "Password is required" : null)
    }
  })

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    const isValid = form.validate()
    if (isValid.hasErrors) {
      event.preventDefault()
    }
  }

  let errorAlert = null
  if (actionData?.error) {
    errorAlert = (
      <Alert title="Invalid credentials" color="red" icon={<IconExclamationCircle />} my="sm">
        {(() => {
          switch (actionData.error_reason) {
            case "invalid_credentials":
              return "Email or password is incorrect."
            case "user_disabled":
              return "Your account is disabled. Please contact support."
            default:
              return "An error occurred. Please try again."
          }
        })()}
      </Alert>
    )
  }

  return (
    <Flex direction="column" align="center" justify="center" h="100vh" bg="gray.0">
      <Card withBorder p="xl" radius="md" w="100%" maw={400}>
        <Card.Section>
          <Center>
            <Omu c="black" />
          </Center>
        </Card.Section>

        {errorAlert}

        <Form method="post" onSubmit={handleSubmit}>
          <Stack>
            <TextInput name="email" label="Email" {...form.getInputProps("email")} />
            <PasswordInput name="password" label="Password" {...form.getInputProps("password")} />
            <Group justify="space-between">
              <Checkbox label="Remember me" />
              <Anchor href="/forgot-password" size="sm">
                Forgot password?
              </Anchor>
            </Group>
            <Button type="submit">Login</Button>
          </Stack>
        </Form>
      </Card>
    </Flex>
  )
}

export async function action({ request }: ActionFunctionArgs) {
  const session = await getSession(request.headers.get("Cookie"))

  const formData = await request.formData()

  const { data, error } = await apiFetchClient.POST("/auth/login", {
    body: {
      email: String(formData.get("email")),
      password: String(formData.get("password"))
    },
    bodySerializer(body) {
      const fd = new FormData()
      fd.append("email", body.email)
      fd.append("password", body.password)
      return fd
    }
  })

  if (data?.session_token) {
    session.set("sessionToken", data.session_token)
    return redirect("/dashboard", { headers: { "Set-Cookie": await commitSession(session) } })
  }

  // Return an error response if login fails
  return json({ error: true, error_reason: error?.detail || "Unknown error" }, { status: 400 })
}
